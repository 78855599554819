export const services = {
    hair: [
        {
          service: "Patch Test & Consultations",
          subService: [
            { name: "Patch Test", duration: "05 min", price: 0, pricesFrom: false },
            { name: "Consultation", duration: "15 min", price: 0, pricesFrom: false }
          ]
        },
        {
          service: "Highlights & Balayage",
          subService: [
            { 
              name: "Full Head Highlights with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 180 },
                { description: "Long Hair", duration: "4h", price: 250 }
              ]
            },
            { 
              name: "Full Head Highlights with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 160 },
                { description: "Long Hair", duration: "4h", price: 230 }
              ]
            },
            { 
              name: "Half Head Highlights with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "3h", price: 165 },
                { description: "Medium Length Hair", duration: "3h", price: 180 },
                { description: "Long Hair", duration: "3h", price: 195 }
              ]
            },
            { 
              name: "Half Head Highlights with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "3h", price: 150 },
                { description: "Medium Length Hair", duration: "3h", price: 165 },
                { description: "Long Hair", duration: "3h", price: 180 }
              ]
            },
            { 
              name: "Highlights with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "3h", price: 165 },
                { description: "Medium Length Hair", duration: "3h", price: 180 },
                { description: "Long Hair", duration: "3h", price: 195 }
              ]
            },
            { 
              name: "T-Section Highlights with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "3h", price: 150 },
                { description: "Medium Length Hair", duration: "3h", price: 165 },
                { description: "Long Hair", duration: "3h", price: 180 }
              ]
            },
            { 
              name: "Balayage with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 180 },
                { description: "Long Hair", duration: "4h", price: 250 }
              ]
            },
            { 
              name: "Balayage with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 160 },
                { description: "Long Hair", duration: "4h", price: 230 }
              ]
            },
            { 
              name: "Ombré with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 160 },
                { description: "Long Hair", duration: "4h", price: 230 }
              ]
            },
            { 
              name: "Ombré with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 180 },
                { description: "Long Hair", duration: "4h", price: 250 }
              ]
            },
            { 
              name: "Balayage & Ombré with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 180 },
                { description: "Long Hair", duration: "4h", price: 250 }
              ]
            },
            { 
              name: "Balayage & Ombré with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "4h", price: 160 },
                { description: "Long Hair", duration: "4h", price: 230 }
              ]
            }
          ]
        },
        {
          service: "Hair Colouring",
          subService: [
            { 
              name: "Toner with Blow Dry", 
              options: [
                { description: "Short", duration: "1h", price: 55 },
                { description: "Medium Length Hair", duration: "1h", price: 65 },
                { description: "Long Hair", duration: "1h 15 min", price: 75 }
              ]
            },
            { 
              name: "Toner with Haircut & Blow Dry", 
              options: [
                { description: "Short", duration: "1h", price: 70 },
                { description: "Medium Length Hair", duration: "1h", price: 85 },
                { description: "Long Hair", duration: "1h 15 min", price: 90 }
              ]
            },
            { 
              name: "Full Head Colour with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "2h", price: 70 },
                { description: "Medium Length Hair", duration: "2h 30 min", price: 100 },
                { description: "Long Hair", duration: "2h 30 min", price: 125 }
              ]
            },
            { 
              name: "Full Head Colour with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "2h", price: 85 },
                { description: "Medium Length Hair", duration: "2h 30 min", price: 115 },
                { description: "Long Hair", duration: "2h 30 min", price: 140 }
              ]
            },
            { 
              name: "Full Head Bleach with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "2h 15 min", price: 255 },
                { description: "Medium Length Hair", duration: "2h 15 min", price: 280 },
                { description: "Long Hair", duration: "2h 15 min", price: 295 }
              ]
            },
            { 
              name: "Root Bleach with Blow Dry", 
              options: [
                { description: "Standard", duration: "2h", price: 65 }
              ]
            },
            { 
              name: "Root Colour", 
              options: [
                { description: "Standard", duration: "2h", price: 50 }
              ]
            }
          ]
        },
        {
          service: "Luxury Extensions",
          subService: [
            { 
              name: "Tape-In Hair Extensions", 
              options: [
                { description: "1h 30 min – 4h", price: 55, priceMax: 150 }
              ]
            },
            { 
              name: "Nano Ring Hair Extensions", 
              options: [
                { description: "1h 30 min – 4h", price: 80, priceMax: 190 }
              ]
            },
            { 
              name: "Micro Ring Hair Extensions", 
              options: [
                { description: "1h 30 min – 4h", price: 150, additionalInfo: "per 150g" }
              ]
            },
            { 
              name: "Hair Extensions Maintenance", 
              options: [
                { description: "2h – 2h 30 min", price: 50, priceMax: 70 }
              ]
            },
            { 
              name: "Re-Tape", 
              options: [
                { description: "1h", price: 30 }
              ]
            },
            { 
              name: "F-Tips Extensions", 
              options: [
                { description: "5h – 7h", price: 150, priceMax: 240 }
              ]
            },
            { 
              name: "K-Tips Extensions", 
              options: [
                { description: "7h – 8h", price: 375, priceMax: 475 }
              ]
            }
          ]
        },
        {
          service: "Haircuts & Hairdressing",
          subService: [
            { 
              name: "Wash, Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "45 min", price: 45 },
                { description: "Medium Length Hair", duration: "1h", price: 55 },
                { description: "Long Hair", duration: "1h", price: 65 }
              ]
            },
            { 
              name: "Dry Haircut", 
              options: [
                { description: "Short Hair", duration: "45 min", price: 35 },
                { description: "Medium Length Hair", duration: "45 min", price: 45 },
                { description: "Long Hair", duration: "45 min", price: 55 }
              ]
            },
            { 
              name: "Fringe Trim", 
              options: [
                { description: "Standard", duration: "20 min", price: 15 }
              ]
            },
            { 
              name: "Blow Dry", 
              options: [
                { description: "Short Hair", duration: "30 min", price: 20 },
                { description: "Medium Length Hair", duration: "45 min", price: 30 },
                { description: "Long Hair", duration: "45 min", price: 40 }
              ]
            },
            { 
              name: "Wash & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "30 min", price: 25 },
                { description: "Medium Length Hair", duration: "45 min", price: 35 },
                { description: "Long Hair", duration: "45 min", price: 45 }
              ]
            },
            { 
              name: "Luxury Wash & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "1h 30 min", price: 55 },
                { description: "Medium Length Hair", duration: "2h", price: 65 },
                { description: "Long Hair", duration: "2h 30 min", price: 75 }
              ]
            },
            { 
              name: "Silk Press Treatment", 
              options: [
                { description: "Short Hair", duration: "2h 30 min", price: 75 },
                { description: "Medium Length Hair", duration: "2h 30 min", price: 85 },
                { description: "Long Hair", duration: "3h", price: 95 }
              ]
            }
          ]
        },
        {
          service: "Hair Treatments",
          subService: [
            {
              name: "Hot Oil Treatment", description: "Standard", duration: "60m", price: 50, pricesFrom: false 
              
            },
            { 
              name: "Hair Botox Treatment", 
              options: [
                { description: "Short Hair", duration: "3h 30 min", price: 90 },
                { description: "Medium Length Hair", duration: "3h 30 min", price: 120 },
                { description: "Long Hair", duration: "3h 30 min", price: 150 }
              ]
            },
            { 
              name: "Hair Conditioning Treatment with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "1h", price: 45 },
                { description: "Medium Length Hair", duration: "1h", price: 55 },
                { description: "Long Hair", duration: "1h", price: 65 }
              ]
            },
            { 
              name: "Olaplex Treatment with Blow Dry", 
              options: [
                { description: "Short Hair", duration: "1h", price: 45 },
                { description: "Medium Length Hair", duration: "1h", price: 55 },
                { description: "Long Hair", duration: "1h", price: 65 }
              ]
            },
            { 
              name: "Olaplex Treatment with Haircut & Blow Dry", 
              options: [
                { description: "Short Hair", duration: "1h 30 min", price: 60 },
                { description: "Medium Length Hair", duration: "1h 30 min", price: 70 },
                { description: "Long Hair", duration: "1h 30 min", price: 80 }
              ]
            },
            { 
              name: "Avlon Texture Release Treatment", 
              options: [
                { description: "Short Hair", duration: "3h 30 min", price: 130 },
                { description: "Medium Length Hair", duration: "4h", price: 150 },
                { description: "Long Hair", duration: "5h", price: 180 }
              ]
            },
            { 
              name: "Keratin & Permanent Straightening Treatment", 
              options: [
                { description: "Short Hair", duration: "3h 30 min", price: 90 },
                { description: "Medium Length Hair", duration: "3h 30 min", price: 120 },
                { description: "Long Hair", duration: "3h 30 min", price: 150 }
              ]
            }
          ]
        },
        {
          service: "Braids & Locs",
          subService: [
            { 
              name: "Female Stitch Braids", 
              options: [
                { description: "4 stitch braids", duration: "1h 30m", price: 45 },
                { description: "6 stitch braids", duration: "2h 30m", price: 50 },
                { description: "8 stitch braids", duration: "3h", price: 60 },
                { description: "10+ stitch braids", duration: "4h", price: 75 }
              ]
            },
            { 
              name: "Loc Retwist", 
              options: [
                { description: "Standard", duration: "2h 30m", price: 60 }
              ]
            },
            { 
              name: "Loc Retwist & Style", 
              options: [
                { description: "Standard", duration: "3h 30m", price: 80 }
              ]
            },
            { 
              name: "Invisible Locs", 
              options: [
                { description: "Mid Back Length", duration: "5h 30m", price: 80 },
                { description: "Waist Length", duration: "6h 30m", price: 95 },
                { description: "Hip Length", duration: "7h", price: 110 }
              ]
            },
            { 
              name: "Knotless Braids", 
              options: [
                { description: "Large Knotless Braids", duration: "3h", price: 55 },
                { description: "Medium Knotless Braids", duration: "4h 30m", price: 65 },
                { description: "Small Knotless Braids", duration: "6h", price: 85 }
              ]
            },
            { 
              name: "Fulani Braids", 
              options: [
                { description: "Large Fulani Braids", duration: "4h", price: 65 },
                { description: "Medium Fulani Braids", duration: "5h", price: 85 },
                { description: "Small Fulani Braids", duration: "6h", price: 100 }
              ]
            },
            { 
              name: "Butterfly Locs", 
              options: [
                { description: "Handmade Locs - Large", duration: "4h 30m", price: 85 },
                { description: "Handmade Locs - Medium", duration: "6h", price: 95 },
                { description: "Handmade Locs - Small", duration: "7h 30m", price: 140 },
                { description: "Crochet Locs - Large", duration: "3h 30m", price: 70 },
                { description: "Crochet Locs - Medium", duration: "5h", price: 110 },
                { description: "Crochet Locs - Small", duration: "6h 30m", price: 120 }
              ]
            },
            { 
              name: "Boho Braids", 
              options: [
                { description: "Large Boho Braids", duration: "4h 30m", price: 80 },
                { description: "Medium Boho Braids", duration: "5h 30m", price: 100 },
                { description: "Small Boho Braids", duration: "6h 30m", price: 120 }
              ]
            },
            { 
              name: "Alicia Keys Braids", 
              options: [
                { description: "4-8 braids", duration: "2h", price: 65 },
                { description: "8-10 braids", duration: "3h", price: 80 },
                { description: "12+ braids", duration: "4h 30m", price: 90 }
              ]
            },
            { 
              name: "ADD-ONS", 
              options: [
                { description: "Waist Length", duration: "+1h", price: 15 },
                { description: "Hip Length", duration: "+1h 30m", price: 22 },
                { description: "Beads (Not provided)", duration: "+45 min", price: 5 }
              ]
            }
          ]
        }
      ],
      
  
    nails: [


      { 
        service: "Natural Nails ", 
        subService: [
          { name: "Gel Polish On Natural Nails", options: [
            { description: "", duration: "2h", price: 25 },
            { description: "", duration: "3h", price: 30 },
          ] },
          { name: "Acrylic Overlay" , options: [
            { description: "", duration: "2h", price: 30 },
            { description: "", duration: "3h", price: 45 },
          ] },
          { name: "Toes", options: [
            { description: "", duration: "2h", price: 25 },
            { description: "", duration: "3h", price: 35 },
          ]},

          
        ]
      },
      { 
        service: "Nail Extensions", 
        subService: [
          { name: "Gel Nail Extensions", options: [
            { description: "", duration: "2h", price: 50 },
            { description: "", duration: "3h", price: 55 },
          ] },
          { name: "Acrylic Extensions", options: [
            { description: "", duration: "2h", price: 45 },
            { description: "", duration: "3h", price: 50 },
          ] },
          { name: "Toes", price: 35 },

        ]
      },
      {
        service: "Manicure & Pedicures",
        subService: [
          { name: "Classic Manicure", price: 20, duration: "1hr" },
          { name: "Spa Manicure", price: 30, duration: "1hr30m" },
          { name: "Russian Manicure", options: [
            { description: "", duration: "2h", price: 25 },
            { description: "", duration: "3h", price: 35 },
          ] },
          { name: "Dry Pedicure", price: 30, duration: "1hr" },
          { name: "Deluxe Spa Pedicure", options: [
            { description: "", duration: "2h", price: 40 },
            { description: "", duration: "3h", price: 45 },
          ] },
        ]
      },
      { 
        service: "Removals & Repairs", 
        subService: [
          { name: "Nail Repair", price: 6, pricesFrom: false },
          { name: "Gel Removal", price: 10, pricesFrom: false },
          { name: "Gel Removal & Nail Tidy", price: 35, pricesFrom: false },
          { name: "Acrylic Removal", price: 20, pricesFrom: false }
  
          
        ]
      },
    ],
     
    beauty: [
      { 
        service: "Facial Threading", 
        subService: [
          { name: "Chin", price: 5, duration: "10 min" },
          { name: "Forehead", price: 5, duration: "10 min" },
          { name: "Lower Lip", price: 5, duration: "10 min" },
          { name: "Upper Lip", price: 5, duration: "10 min" },
          { name: "Lip & Chin", price: 8, duration: "15 min" },
          { name: "Sides", price: 10, duration: "10 min" },
          { name: "Full Face", price: 25, duration: "30 min" }
        ]
      },
      { 
        service: "Eyebrow & Eyelash Tinting", 
        subService: [
          { name: "Eyebrow Tint", price: 12, duration: "20 min" },
          { name: "Eyelash Tint", price: 12, duration: "20 min" },
          { name: "Eyebrow & Eyelash Tint", price: 18, duration: "20 min" },
          { name: "Eyebrow Threading", price: 10, duration: "10 min" },
          { name: "Eyebrow Waxing", price: 10, duration: "20 min" }
        ]
      },
      { 
        service: "Lash & Brow Treatments", 
        subService: [
          { name: "Lash Lift", price: 35, duration: "45 min" },
          { name: "Lash Lift & Tint", price: 40, duration: "1 hr" },
          { name: "Brow Lamination", price: 55, duration: "1 hr" },
          { name: "Brow Lamination & Tint", price: 65, duration: "1 hr" },
          { name: "Eyebrow Thread & Tint", price: 20, duration: "30 min" },
          { name: "Eyebrow Wax & Tint", price: 20, duration: "30 min" },
          { name: "Henna Brows", price: 15, duration: "20 min" }
        ]
      }
    ],
  };
  
  export const stylists = {
    
    yolanda: [
          {
            service: "Braids & Locs",
            subService: [
              {
                name: "ADD-ONS",
                options: [
                  { description: "Waist Length", duration: "+1h", price: 15 },
                  { description: "Hip Length", duration: "+1h30m", price: 22 },
                  { description: "Beads (Not provided)", duration: "+45 min", price: 5 }
                ],
                minPrice: 5,
                maxPrice: 22
              },
              {
                name: "Female Stitch Braids",
                options: [
                  { description: "4 stitch braids", duration: "1h30m", price: 45 },
                  { description: "6 stitch braids", duration: "2h30m", price: 50 },
                  { description: "8 stitch braids", duration: "3h", price: 60 },
                  { description: "10+ stitch braids", duration: "4h", price: 75 }
                ],
                minPrice: 45,
                maxPrice: 75
              },
              {
                name: "Invisible Locs",
                options: [
                  { description: "Mid Back Length", duration: "5h30m", price: 80 },
                  { description: "Waist Length", duration: "6h30m", price: 95 },
                  { description: "Hip Length", duration: "7h", price: 110 }
                ],
                minPrice: 80,
                maxPrice: 110
              },
              {
                name: "Knotless Braids",
                options: [
                  { description: "Large Knotless Braids", duration: "3h", price: 55 },
                  { description: "Medium Knotless Braids", duration: "4h30m", price: 65 },
                  { description: "Small Knotless Braids", duration: "6h", price: 85 }
                ],
                minPrice: 55,
                maxPrice: 85
              },
              {
                name: "Fulani Braids",
                options: [
                  { description: "Large Fulani Braids", duration: "4h", price: 65 },
                  { description: "Medium Fulani Braids", duration: "5h", price: 85 },
                  { description: "Small Fulani Braids", duration: "6h", price: 100 }
                ],
                minPrice: 65,
                maxPrice: 100
              },
              {
                name: "Butterfly Locs",
                options: [
                  { description: "Handmade Locs - Large", duration: "4h30m", price: 85 },
                  { description: "Handmade Locs - Medium", duration: "6h", price: 95 },
                  { description: "Handmade Locs - Small", duration: "7h30m", price: 140 },
                  { description: "Crochet Locs - Large", duration: "3h30m", price: 70 },
                  { description: "Crochet Locs - Medium", duration: "5h", price: 110 },
                  { description: "Crochet Locs - Small", duration: "6h30m", price: 120 }
                ],
                minPrice: 70,
                maxPrice: 140
              },
              {
                name: "Boho Braids",
                options: [
                  { description: "Large Boho Braids", duration: "4h30m", price: 80 },
                  { description: "Medium Boho Braids", duration: "5h30m", price: 100 },
                  { description: "Small Boho Braids", duration: "6h30m", price: 120 }
                ],
                minPrice: 80,
                maxPrice: 120
              },
              {
                name: "Alicia Keys Braids",
                options: [
                  { description: "4-8 braids", duration: "2h", price: 65 },
                  { description: "8-10 braids", duration: "3h", price: 80 },
                  { description: "12+ braids", duration: "4h30m", price: 90 }
                ],
                minPrice: 65,
                maxPrice: 90
              }
            ]
          }
        ],
    
        giselle: [
              {
                service: "Braids & Locs",
                subService: [
                  {
                    name: "ADD-ONS",
                    options: [
                      { description: "Waist Length", duration: "+1h", price: 15 },
                      { description: "Hip Length", duration: "+1h30m", price: 22 },
                      { description: "Beads (Not provided)", duration: "+45 min", price: 5 }
                    ],
                    minPrice: 5,
                    maxPrice: 22
                  },
                  {
                    name: "Female Stitch Braids",
                    options: [
                      { description: "4 stitch braids", duration: "1h30m", price: 45 },
                      { description: "6 stitch braids", duration: "2h30m", price: 50 },
                      { description: "8 stitch braids", duration: "3h", price: 60 },
                      { description: "10+ stitch braids", duration: "4h", price: 75 }
                    ],
                    minPrice: 45,
                    maxPrice: 75
                  },
                  {
                    name: "Loc Retwist",
                    options: [
                      { description: "Standard", duration: "2h30m", price: 60 }
                    ],
                    minPrice: 60,
                    maxPrice: 60
                  },
                  {
                    name: "Loc Retwist & Style",
                    options: [
                      { description: "Standard", duration: "3h30m", price: 80 }
                    ],
                    minPrice: 80,
                    maxPrice: 80
                  },
                  {
                    name: "Invisible Locs",
                    options: [
                      { description: "Mid Back Length", duration: "5h30m", price: 80 },
                      { description: "Waist Length", duration: "6h30m", price: 95 },
                      { description: "Hip Length", duration: "7h", price: 110 }
                    ],
                    minPrice: 80,
                    maxPrice: 110
                  },
                  {
                    name: "Knotless Braids",
                    options: [
                      { description: "Large Knotless Braids", duration: "3h", price: 55 },
                      { description: "Medium Knotless Braids", duration: "4h30m", price: 65 },
                      { description: "Small Knotless Braids", duration: "6h", price: 85 }
                    ],
                    minPrice: 55,
                    maxPrice: 85
                  },
                  {
                    name: "Fulani Braids",
                    options: [
                      { description: "Large Fulani Braids", duration: "4h", price: 65 },
                      { description: "Medium Fulani Braids", duration: "5h", price: 85 },
                      { description: "Small Fulani Braids", duration: "6h", price: 100 }
                    ],
                    minPrice: 65,
                    maxPrice: 100
                  },
                  {
                    name: "Butterfly Locs",
                    options: [
                      { description: "Handmade Locs - Large", duration: "4h30m", price: 85 },
                      { description: "Handmade Locs - Medium", duration: "6h", price: 95 },
                      { description: "Handmade Locs - Small", duration: "7h30m", price: 140 },
                      { description: "Crochet Locs - Large", duration: "3h30m", price: 70 },
                      { description: "Crochet Locs - Medium", duration: "5h", price: 110 },
                      { description: "Crochet Locs - Small", duration: "6h30m", price: 120 }
                    ],
                    minPrice: 70,
                    maxPrice: 140
                  },
                  {
                    name: "Boho Braids",
                    options: [
                      { description: "Large Boho Braids", duration: "4h30m", price: 80 },
                      { description: "Medium Boho Braids", duration: "5h30m", price: 100 },
                      { description: "Small Boho Braids", duration: "6h30m", price: 120 }
                    ],
                    minPrice: 80,
                    maxPrice: 120
                  },
                  {
                    name: "Alicia Keys Braids",
                    options: [
                      { description: "4-8 braids", duration: "2h", price: 65 },
                      { description: "8-10 braids", duration: "3h", price: 80 },
                      { description: "12+ braids", duration: "4h30m", price: 90 }
                    ],
                    minPrice: 65,
                    maxPrice: 90
                  }
                ]
              }
            ],
        
            
              mozhi: [
                {
                  "service": "Highlights & Balayage",
                  "subService": [
                    {
                      "name": "Full Head Highlights with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Full Head Highlights with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    },
                    {
                      "name": "Half Head Highlights with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "3h", "price": 165 },
                        { "description": "Medium Length Hair", "duration": "3h", "price": 180 },
                        { "description": "Long Hair", "duration": "3h", "price": 195 }
                      ],
                      "minPrice": 165,
                      "maxPrice": 195
                    },
                    {
                      "name": "Half Head Highlights with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "3h", "price": 150 },
                        { "description": "Medium Length Hair", "duration": "3h", "price": 165 },
                        { "description": "Long Hair", "duration": "3h", "price": 180 }
                      ],
                      "minPrice": 150,
                      "maxPrice": 180
                    },
                    {
                      "name": "T-Section Highlights with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "3h", "price": 150 },
                        { "description": "Medium Length Hair", "duration": "3h", "price": 165 },
                        { "description": "Long Hair", "duration": "3h", "price": 180 }
                      ],
                      "minPrice": 150,
                      "maxPrice": 180
                    },
                    {
                      "name": "Balayage with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Balayage with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    },
                    {
                      "name": "Ombré with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    },
                    {
                      "name": "Ombré with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Balayage & Ombré with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Balayage & Ombré with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    }
                  ]
                },
                {
                  "service": "Hair Colouring",
                  "subService": [
                    {
                      "name": "Toner with Blow Dry",
                      "options": [
                        { "description": "Short", "duration": "1h", "price": 55 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 65 },
                        { "description": "Long Hair", "duration": "1h15m", "price": 75 }
                      ],
                      "minPrice": 55,
                      "maxPrice": 75
                    },
                    {
                      "name": "Toner with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short", "duration": "1h", "price": 70 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 85 },
                        { "description": "Long Hair", "duration": "1h15m", "price": 90 }
                      ],
                      "minPrice": 70,
                      "maxPrice": 90
                    },
                    {
                      "name": "Full Head Colour with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "2h", "price": 70 },
                        { "description": "Medium Length Hair", "duration": "2h30m", "price": 100 },
                        { "description": "Long Hair", "duration": "2h30m", "price": 125 }
                      ],
                      "minPrice": 70,
                      "maxPrice": 125
                    },
                    {
                      "name": "Full Head Colour with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "2h", "price": 85 },
                        { "description": "Medium Length Hair", "duration": "2h30m", "price": 115 },
                        { "description": "Long Hair", "duration": "2h30m", "price": 140 }
                      ],
                      "minPrice": 85,
                      "maxPrice": 140
                    },
                    {
                      "name": "Full Head Bleach with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "2h15m", "price": 255 },
                        { "description": "Medium Length Hair", "duration": "2h15m", "price": 280 },
                        { "description": "Long Hair", "duration": "2h15m", "price": 295 }
                      ],
                      "minPrice": 255,
                      "maxPrice": 295
                    },
                    {
                      "name": "Root Bleach with Blow Dry",
                      "options": [{ "description": "Standard", "duration": "2h", "price": 65 }],
                      "minPrice": 65,
                      "maxPrice": 65
                    },
                    {
                      "name": "Root Colour",
                      "options": [{ "description": "Standard", "duration": "2h", "price": 50 }],
                      "minPrice": 50,
                      "maxPrice": 50
                    }
                  ]
                },
                {
                  "service": "Luxury Extensions",
                  "subService": [
                    {
                      "name": "Tape-In Hair Extensions",
                      "options": [
                        { "description": "Standard", "duration": "1h30min-4h", "price": 55 },
                        { "description": "Extended", "duration": "4h", "price": 150 }
                      ],
                      "minPrice": 55,
                      "maxPrice": 150
                    },
                    {
                      "name": "Nano Ring Hair Extensions",
                      "options": [
                        { "description": "Standard", "duration": "1h30min-4h", "price": 80 },
                        { "description": "Extended", "duration": "4h", "price": 190 }
                      ],
                      "minPrice": 80,
                      "maxPrice": 190
                    },
                    {
                      "name": "Micro Ring Hair Extensions",
                      "options": [
                        { "description": "Standard", "duration": "1h30min-4h", "price": 150 }
                      ],
                      "minPrice": 150,
                      "maxPrice": 150
                    },
                    {
                      "name": "Hair Extensions Maintenance",
                      "options": [
                        { "description": "Standard", "duration": "2h", "price": 50 },
                        { "description": "Extended", "duration": "2h30min", "price": 70 }
                      ],
                      "minPrice": 50,
                      "maxPrice": 70
                    },
                    {
                      "name": "Re-Tape",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 30 }],
                      "minPrice": 30,
                      "maxPrice": 30
                    }
                  ]
                },
                {
                  "service": "Haircuts & Hairdressing",
                  "subService": [
                    {
                      "name": "Wash, Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "45min", "price": 45 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 55 },
                        { "description": "Long Hair", "duration": "1h", "price": 65 }
                      ],
                      "minPrice": 45,
                      "maxPrice": 65
                    },
                    {
                      "name": "Dry Haircut",
                      "options": [
                        { "description": "Short Hair", "duration": "45min", "price": 35 },
                        { "description": "Medium Length Hair", "duration": "45min", "price": 45 },
                        { "description": "Long Hair", "duration": "45min", "price": 55 }
                      ],
                      "minPrice": 35,
                      "maxPrice": 55
                    },
                    {
                      "name": "Fringe Trim",
                      "options": [{ "description": "Standard", "duration": "20min", "price": 15 }],
                      "minPrice": 15,
                      "maxPrice": 15
                    },
                    {
                      "name": "Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "30min", "price": 20 },
                        { "description": "Medium Length Hair", "duration": "45min", "price": 30 },
                        { "description": "Long Hair", "duration": "45min", "price": 40 }
                      ],
                      "minPrice": 20,
                      "maxPrice": 40
                    },
                    {
                      "name": "Wash & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "30min", "price": 25 },
                        { "description": "Medium Length Hair", "duration": "45min", "price": 35 },
                        { "description": "Long Hair", "duration": "45min", "price": 45 }
                      ],
                      "minPrice": 25,
                      "maxPrice": 45
                    },
                    {
                      "name": "Luxury Wash & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h30min", "price": 55 },
                        { "description": "Medium Length Hair", "duration": "2h", "price": 65 },
                        { "description": "Long Hair", "duration": "2h30min", "price": 75 }
                      ],
                      "minPrice": 55,
                      "maxPrice": 75
                    }
                  ]
                },
                {
                  "service": "Hair Treatments",
                  "subService": [
                    {
                      "name": "Hair Botox Treatment",
                      "options": [
                        { "description": "Short Hair", "duration": "3h30min", "price": 90 },
                        { "description": "Medium Length Hair", "duration": "3h30min", "price": 120 },
                        { "description": "Long Hair", "duration": "3h30min", "price": 150 }
                      ],
                      "minPrice": 90,
                      "maxPrice": 150
                    },
                    {
                      "name": "Hair Conditioning Treatment with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h", "price": 45 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 55 },
                        { "description": "Long Hair", "duration": "1h", "price": 65 }
                      ],
                      "minPrice": 45,
                      "maxPrice": 65
                    },
                    {
                      "name": "Olaplex Treatment with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h", "price": 45 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 55 },
                        { "description": "Long Hair", "duration": "1h", "price": 65 }
                      ],
                      "minPrice": 45,
                      "maxPrice": 65
                    },
                    {
                      "name": "Olaplex Treatment with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h30min", "price": 60 },
                        { "description": "Medium Length Hair", "duration": "1h30min", "price": 70 },
                        { "description": "Long Hair", "duration": "1h30min", "price": 80 }
                      ],
                      "minPrice": 60,
                      "maxPrice": 80
                    },
                    {
                      "name": "Avlon Texture release treatment",
                      "options": [
                        { "description": "Short Hair", "duration": "3h30min", "price": 130 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 150 },
                        { "description": "Long Hair", "duration": "5h", "price": 180 }
                      ],
                      "minPrice": 130,
                      "maxPrice": 180
                    },
                    {
                      "name": "Keratin & Permanent Straightening Treatment",
                      "options": [
                        { "description": "Short Hair", "duration": "3h30min", "price": 90 },
                        { "description": "Medium Length Hair", "duration": "3h30min", "price": 120 },
                        { "description": "Long Hair", "duration": "3h30min", "price": 150 }
                      ],
                      "minPrice": 90,
                      "maxPrice": 150
                    }
                  ]
                },
                {
                  "service": "Beauty",
                  "subService": [
                    {
                      "name": "Facial Threading",
                      "options": [
                        { "description": "Chin", "duration": "10min", "price": 5 },
                        { "description": "Forehead", "duration": "10min", "price": 5 },
                        { "description": "Lower Lip", "duration": "10min", "price": 5 },
                        { "description": "Upper Lip", "duration": "10min", "price": 5 },
                        { "description": "Lip & Chin", "duration": "15min", "price": 8 },
                        { "description": "Sides", "duration": "10min", "price": 10 },
                        { "description": "Full Face", "duration": "30min", "price": 25 }
                      ],
                      "minPrice": 5,
                      "maxPrice": 25
                    },
                    {
                      "name": "Eyebrow & Eyelash Tinting",
                      "options": [
                        { "description": "Eyebrow", "duration": "20min", "price": 12 },
                        { "description": "Eyelash", "duration": "20min", "price": 12 },
                        { "description": "Eyebrow & Eyelash", "duration": "20min", "price": 18 }
                      ],
                      "minPrice": 12,
                      "maxPrice": 18
                    },
                    {
                      "name": "Eyebrow Threading",
                      "options": [{ "description": "Standard", "duration": "10min", "price": 10 }],
                      "minPrice": 10,
                      "maxPrice": 10
                    },
                    {
                      "name": "Eyebrow Waxing",
                      "options": [{ "description": "Standard", "duration": "20min", "price": 10 }],
                      "minPrice": 10,
                      "maxPrice": 10
                    },
                    {
                      "name": "Lash Lift",
                      "options": [{ "description": "Standard", "duration": "45min", "price": 35 }],
                      "minPrice": 35,
                      "maxPrice": 35
                    },
                    {
                      "name": "Lash Lift & Tint",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 40 }],
                      "minPrice": 40,
                      "maxPrice": 40
                    },
                    {
                      "name": "Brow Lamination",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 55 }],
                      "minPrice": 55,
                      "maxPrice": 55
                    },
                    {
                      "name": "Brow Lamination & Tint",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 65 }],
                      "minPrice": 65,
                      "maxPrice": 65
                    },
                    {
                      "name": "Eyebrow Thread & Tint",
                      "options": [{ "description": "Standard", "duration": "30min", "price": 20 }],
                      "minPrice": 20,
                      "maxPrice": 20
                    },
                    {
                      "name": "Eyebrow Wax & Tint",
                      "options": [{ "description": "Standard", "duration": "30min", "price": 20 }],
                      "minPrice": 20,
                      "maxPrice": 20
                    },
                    {
                      "name": "Henna Brows",
                      "options": [{ "description": "Standard", "duration": "20min", "price": 15 }],
                      "minPrice": 15,
                      "maxPrice": 15
                    },
                    {
                      "name": "Patch Test & Consultations",
                      "options": [
                        { "description": "Patch Test", "duration": "5min", "price": 0 },
                        { "description": "Consultation", "duration": "15min", "price": 0 }
                      ],
                      "minPrice": 0,
                      "maxPrice": 0
                    }
                  ]
                }
              ],
            
            
              flora: [
                {
                  "service": "Highlights & Balayage",
                  "subService": [
                    {
                      "name": "Full Head Highlights with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Full Head Highlights with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    },
                    {
                      "name": "Half Head Highlights with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "3h", "price": 165 },
                        { "description": "Medium Length Hair", "duration": "3h", "price": 180 },
                        { "description": "Long Hair", "duration": "3h", "price": 195 }
                      ],
                      "minPrice": 165,
                      "maxPrice": 195
                    },
                    {
                      "name": "Half Head Highlights with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "3h", "price": 150 },
                        { "description": "Medium Length Hair", "duration": "3h", "price": 165 },
                        { "description": "Long Hair", "duration": "3h", "price": 180 }
                      ],
                      "minPrice": 150,
                      "maxPrice": 180
                    },
                    {
                      "name": "T-Section Highlights with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "3h", "price": 150 },
                        { "description": "Medium Length Hair", "duration": "3h", "price": 165 },
                        { "description": "Long Hair", "duration": "3h", "price": 180 }
                      ],
                      "minPrice": 150,
                      "maxPrice": 180
                    },
                    {
                      "name": "Balayage with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Balayage with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    },
                    {
                      "name": "Ombré with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    },
                    {
                      "name": "Ombré with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Balayage & Ombré with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 250 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 275 },
                        { "description": "Long Hair", "duration": "4h", "price": 300 }
                      ],
                      "minPrice": 250,
                      "maxPrice": 300
                    },
                    {
                      "name": "Balayage & Ombré with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "4h", "price": 235 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 250 },
                        { "description": "Long Hair", "duration": "4h", "price": 275 }
                      ],
                      "minPrice": 235,
                      "maxPrice": 275
                    }
                  ]
                },
                {
                  "service": "Hair Colouring",
                  "subService": [
                    {
                      "name": "Toner with Blow Dry",
                      "options": [
                        { "description": "Short", "duration": "1h", "price": 55 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 65 },
                        { "description": "Long Hair", "duration": "1h15m", "price": 75 }
                      ],
                      "minPrice": 55,
                      "maxPrice": 75
                    },
                    {
                      "name": "Toner with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short", "duration": "1h", "price": 70 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 85 },
                        { "description": "Long Hair", "duration": "1h15m", "price": 90 }
                      ],
                      "minPrice": 70,
                      "maxPrice": 90
                    },
                    {
                      "name": "Full Head Colour with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "2h", "price": 70 },
                        { "description": "Medium Length Hair", "duration": "2h30m", "price": 100 },
                        { "description": "Long Hair", "duration": "2h30m", "price": 125 }
                      ],
                      "minPrice": 70,
                      "maxPrice": 125
                    },
                    {
                      "name": "Full Head Colour with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "2h", "price": 85 },
                        { "description": "Medium Length Hair", "duration": "2h30m", "price": 115 },
                        { "description": "Long Hair", "duration": "2h30m", "price": 140 }
                      ],
                      "minPrice": 85,
                      "maxPrice": 140
                    },
                    {
                      "name": "Full Head Bleach with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "2h15m", "price": 255 },
                        { "description": "Medium Length Hair", "duration": "2h15m", "price": 280 },
                        { "description": "Long Hair", "duration": "2h15m", "price": 295 }
                      ],
                      "minPrice": 255,
                      "maxPrice": 295
                    },
                    {
                      "name": "Root Bleach with Blow Dry",
                      "options": [{ "description": "Standard", "duration": "2h", "price": 65 }],
                      "minPrice": 65,
                      "maxPrice": 65
                    },
                    {
                      "name": "Root Colour",
                      "options": [{ "description": "Standard", "duration": "2h", "price": 50 }],
                      "minPrice": 50,
                      "maxPrice": 50
                    }
                  ]
                },
                {
                  "service": "Luxury Extensions",
                  "subService": [
                    {
                      "name": "Tape-In Hair Extensions",
                      "options": [
                        { "description": "Standard", "duration": "1h30min-4h", "price": 55 },
                        { "description": "Extended", "duration": "4h", "price": 150 }
                      ],
                      "minPrice": 55,
                      "maxPrice": 150
                    },
                    {
                      "name": "Nano Ring Hair Extensions",
                      "options": [
                        { "description": "Standard", "duration": "1h30min-4h", "price": 80 },
                        { "description": "Extended", "duration": "4h", "price": 190 }
                      ],
                      "minPrice": 80,
                      "maxPrice": 190
                    },
                    {
                      "name": "Micro Ring Hair Extensions",
                      "options": [
                        { "description": "Standard", "duration": "1h30min-4h", "price": 150 }
                      ],
                      "minPrice": 150,
                      "maxPrice": 150
                    },
                    {
                      "name": "Hair Extensions Maintenance",
                      "options": [
                        { "description": "Standard", "duration": "2h", "price": 50 },
                        { "description": "Extended", "duration": "2h30min", "price": 70 }
                      ],
                      "minPrice": 50,
                      "maxPrice": 70
                    },
                    {
                      "name": "Re-Tape",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 30 }],
                      "minPrice": 30,
                      "maxPrice": 30
                    }
                  ]
                },
                {
                  "service": "Haircuts & Hairdressing",
                  "subService": [
                    {
                      "name": "Wash, Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "45min", "price": 45 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 55 },
                        { "description": "Long Hair", "duration": "1h", "price": 65 }
                      ],
                      "minPrice": 45,
                      "maxPrice": 65
                    },
                    {
                      "name": "Dry Haircut",
                      "options": [
                        { "description": "Short Hair", "duration": "45min", "price": 35 },
                        { "description": "Medium Length Hair", "duration": "45min", "price": 45 },
                        { "description": "Long Hair", "duration": "45min", "price": 55 }
                      ],
                      "minPrice": 35,
                      "maxPrice": 55
                    },
                    {
                      "name": "Fringe Trim",
                      "options": [{ "description": "Standard", "duration": "20min", "price": 15 }],
                      "minPrice": 15,
                      "maxPrice": 15
                    },
                    {
                      "name": "Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "30min", "price": 20 },
                        { "description": "Medium Length Hair", "duration": "45min", "price": 30 },
                        { "description": "Long Hair", "duration": "45min", "price": 40 }
                      ],
                      "minPrice": 20,
                      "maxPrice": 40
                    },
                    {
                      "name": "Wash & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "30min", "price": 25 },
                        { "description": "Medium Length Hair", "duration": "45min", "price": 35 },
                        { "description": "Long Hair", "duration": "45min", "price": 45 }
                      ],
                      "minPrice": 25,
                      "maxPrice": 45
                    },
                    {
                      "name": "Luxury Wash & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h30min", "price": 55 },
                        { "description": "Medium Length Hair", "duration": "2h", "price": 65 },
                        { "description": "Long Hair", "duration": "2h30min", "price": 75 }
                      ],
                      "minPrice": 55,
                      "maxPrice": 75
                    }
                  ]
                },
                {
                  "service": "Hair Treatments",
                  "subService": [
                    {
                      "name": "Hair Botox Treatment",
                      "options": [
                        { "description": "Short Hair", "duration": "3h30min", "price": 90 },
                        { "description": "Medium Length Hair", "duration": "3h30min", "price": 120 },
                        { "description": "Long Hair", "duration": "3h30min", "price": 150 }
                      ],
                      "minPrice": 90,
                      "maxPrice": 150
                    },
                    {
                      "name": "Hair Conditioning Treatment with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h", "price": 45 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 55 },
                        { "description": "Long Hair", "duration": "1h", "price": 65 }
                      ],
                      "minPrice": 45,
                      "maxPrice": 65
                    },
                    {
                      "name": "Olaplex Treatment with Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h", "price": 45 },
                        { "description": "Medium Length Hair", "duration": "1h", "price": 55 },
                        { "description": "Long Hair", "duration": "1h", "price": 65 }
                      ],
                      "minPrice": 45,
                      "maxPrice": 65
                    },
                    {
                      "name": "Olaplex Treatment with Haircut & Blow Dry",
                      "options": [
                        { "description": "Short Hair", "duration": "1h30min", "price": 60 },
                        { "description": "Medium Length Hair", "duration": "1h30min", "price": 70 },
                        { "description": "Long Hair", "duration": "1h30min", "price": 80 }
                      ],
                      "minPrice": 60,
                      "maxPrice": 80
                    },
                    {
                      "name": "Avlon Texture release treatment",
                      "options": [
                        { "description": "Short Hair", "duration": "3h30min", "price": 130 },
                        { "description": "Medium Length Hair", "duration": "4h", "price": 150 },
                        { "description": "Long Hair", "duration": "5h", "price": 180 }
                      ],
                      "minPrice": 130,
                      "maxPrice": 180
                    },
                    {
                      "name": "Keratin & Permanent Straightening Treatment",
                      "options": [
                        { "description": "Short Hair", "duration": "3h30min", "price": 90 },
                        { "description": "Medium Length Hair", "duration": "3h30min", "price": 120 },
                        { "description": "Long Hair", "duration": "3h30min", "price": 150 }
                      ],
                      "minPrice": 90,
                      "maxPrice": 150
                    }
                  ]
                },
                {
                  "service": "Beauty",
                  "subService": [
                    {
                      "name": "Facial Threading",
                      "options": [
                        { "description": "Chin", "duration": "10min", "price": 5 },
                        { "description": "Forehead", "duration": "10min", "price": 5 },
                        { "description": "Lower Lip", "duration": "10min", "price": 5 },
                        { "description": "Upper Lip", "duration": "10min", "price": 5 },
                        { "description": "Lip & Chin", "duration": "15min", "price": 8 },
                        { "description": "Sides", "duration": "10min", "price": 10 },
                        { "description": "Full Face", "duration": "30min", "price": 25 }
                      ],
                      "minPrice": 5,
                      "maxPrice": 25
                    },
                    {
                      "name": "Eyebrow & Eyelash Tinting",
                      "options": [
                        { "description": "Eyebrow", "duration": "20min", "price": 12 },
                        { "description": "Eyelash", "duration": "20min", "price": 12 },
                        { "description": "Eyebrow & Eyelash", "duration": "20min", "price": 18 }
                      ],
                      "minPrice": 12,
                      "maxPrice": 18
                    },
                    {
                      "name": "Eyebrow Threading",
                      "options": [{ "description": "Standard", "duration": "10min", "price": 10 }],
                      "minPrice": 10,
                      "maxPrice": 10
                    },
                    {
                      "name": "Eyebrow Waxing",
                      "options": [{ "description": "Standard", "duration": "20min", "price": 10 }],
                      "minPrice": 10,
                      "maxPrice": 10
                    },
                    {
                      "name": "Lash Lift",
                      "options": [{ "description": "Standard", "duration": "45min", "price": 35 }],
                      "minPrice": 35,
                      "maxPrice": 35
                    },
                    {
                      "name": "Lash Lift & Tint",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 40 }],
                      "minPrice": 40,
                      "maxPrice": 40
                    },
                    {
                      "name": "Brow Lamination",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 55 }],
                      "minPrice": 55,
                      "maxPrice": 55
                    },
                    {
                      "name": "Brow Lamination & Tint",
                      "options": [{ "description": "Standard", "duration": "1h", "price": 65 }],
                      "minPrice": 65,
                      "maxPrice": 65
                    },
                    {
                      "name": "Eyebrow Thread & Tint",
                      "options": [{ "description": "Standard", "duration": "30min", "price": 20 }],
                      "minPrice": 20,
                      "maxPrice": 20
                    },
                    {
                      "name": "Eyebrow Wax & Tint",
                      "options": [{ "description": "Standard", "duration": "30min", "price": 20 }],
                      "minPrice": 20,
                      "maxPrice": 20
                    },
                    {
                      "name": "Henna Brows",
                      "options": [{ "description": "Standard", "duration": "20min", "price": 15 }],
                      "minPrice": 15,
                      "maxPrice": 15
                    },
                    {
                      "name": "Patch Test & Consultations",
                      "options": [
                        { "description": "Patch Test", "duration": "5min", "price": 0 },
                        { "description": "Consultation", "duration": "15min", "price": 0 }
                      ],
                      "minPrice": 0,
                      "maxPrice": 0
                    }
                  ]
                }
              ],
   niquee: [
          {
            service: "Luxury Extensions",
            subService: [
              {
                name: "Tape-In Hair Extensions",
                options: [
                  { description: "1h30m - 4h", price: 55, priceMax: 150 }
                ],
                minPrice: 55,
                maxPrice: 150
              },
              {
                name: "Nano Ring Hair Extensions",
                options: [
                  { description: "1h30m - 4h", price: 80, priceMax: 190 }
                ],
                minPrice: 80,
                maxPrice: 190
              },
              {
                name: "Micro Ring Hair Extensions",
                options: [
                  { description: "1h30m - 4h", price: 150, additionalInfo: "per 150g" }
                ],
                minPrice: 150,
                maxPrice: 150
              },
              {
                name: "Hair Extensions Maintenance",
                options: [
                  { description: "2h - 2h30m", price: 50, priceMax: 70 }
                ],
                minPrice: 50,
                maxPrice: 70
              },
              {
                name: "Re-Tape",
                options: [
                  { description: "1h", price: 30 }
                ],
                minPrice: 30,
                maxPrice: 30
              }
            ]
          },
          {
            service: "Haircuts & Hairdressing",
            subService: [
              {
                name: "Blow Dry",
                options: [
                  { description: "Short Hair", duration: "30m", price: 20 },
                  { description: "Medium Length Hair", duration: "45m", price: 30 },
                  { description: "Long Hair", duration: "45m", price: 40 }
                ],
                minPrice: 20,
                maxPrice: 40
              },
              {
                name: "Wash & Blow Dry",
                options: [
                  { description: "Short Hair", duration: "30m", price: 25 },
                  { description: "Medium Length Hair", duration: "45m", price: 35 },
                  { description: "Long Hair", duration: "45m", price: 45 }
                ],
                minPrice: 25,
                maxPrice: 45
              },
              {
                name: "Luxury Wash & Blow Dry",
                options: [
                  { description: "Short Hair", duration: "1h30m", price: 55 },
                  { description: "Medium Length Hair", duration: "2h", price: 65 },
                  { description: "Long Hair", duration: "2h30m", price: 75 }
                ],
                minPrice: 55,
                maxPrice: 75
              },
              {
                name: "Silk Press Treatment",
                options: [
                  { description: "Short Hair", duration: "2h30m", price: 75 },
                  { description: "Medium Length Hair", duration: "2h30m", price: 85 },
                  { description: "Long Hair", duration: "3h", price: 95 }
                ],
                minPrice: 75,
                maxPrice: 95
              }
            ]
          },
          {
            service: "Hair Treatments",
            subService: [
              {
                name: "Hair Botox Treatment",
                options: [
                  { description: "Short Hair", duration: "3h30m", price: 90 },
                  { description: "Medium Length Hair", duration: "3h30m", price: 120 },
                  { description: "Long Hair", duration: "3h30m", price: 150 }
                ],
                minPrice: 90,
                maxPrice: 150
              },
              {
                name: "Hair Conditioning Treatment with Blow Dry",
                options: [
                  { description: "Short Hair", duration: "1h", price: 45 },
                  { description: "Medium Length Hair", duration: "1h", price: 55 },
                  { description: "Long Hair", duration: "1h", price: 65 }
                ],
                minPrice: 45,
                maxPrice: 65
              },
              {
                name: "Olaplex Treatment with Blow Dry",
                options: [
                  { description: "Short Hair", duration: "1h", price: 45 },
                  { description: "Medium Length Hair", duration: "1h", price: 55 },
                  { description: "Long Hair", duration: "1h", price: 65 }
                ],
                minPrice: 45,
                maxPrice: 65
              },
              {
                name: "Olaplex Treatment with Haircut & Blow Dry",
                options: [
                  { description: "Short Hair", duration: "1h30m", price: 60 },
                  { description: "Medium Length Hair", duration: "1h30m", price: 70 },
                  { description: "Long Hair", duration: "1h30m", price: 80 }
                ],
                minPrice: 60,
                maxPrice: 80
              },
              {
                name: "Avlon Texture Release Treatment",
                options: [
                  { description: "Short Hair", duration: "3h30m", price: 130 },
                  { description: "Medium Length Hair", duration: "4h", price: 150 },
                  { description: "Long Hair", duration: "5h", price: 180 }
                ],
                minPrice: 130,
                maxPrice: 180
              },
              {
                name: "Keratin & Permanent Straightening Treatment",
                options: [
                  { description: "Short Hair", duration: "3h30m", price: 90 },
                  { description: "Medium Length Hair", duration: "3h30m", price: 120 },
                  { description: "Long Hair", duration: "3h30m", price: 150 }
                ],
                minPrice: 90,
                maxPrice: 150
              }
            ]
          }
        ],
        shakiyla: [
              {
                service: "Luxury Extensions",
                subService: [
                  {
                    name: "Tape-In Hair Extensions",
                    options: [
                      { description: "1h30m - 4h", price: 55, priceMax: 150 }
                    ],
                    minPrice: 55,
                    maxPrice: 150
                  },
                  {
                    name: "Nano Ring Hair Extensions",
                    options: [
                      { description: "1h30m - 4h", price: 80, priceMax: 190 }
                    ],
                    minPrice: 80,
                    maxPrice: 190
                  },
                  {
                    name: "Micro Ring Hair Extensions",
                    options: [
                      { description: "1h30m - 4h", price: 150, additionalInfo: "per 150g" }
                    ],
                    minPrice: 150,
                    maxPrice: 150
                  },
                  {
                    name: "Hair Extensions Maintenance",
                    options: [
                      { description: "2h - 2h30m", price: 50, priceMax: 70 }
                    ],
                    minPrice: 50,
                    maxPrice: 70
                  },
                  {
                    name: "Re-Tape",
                    options: [
                      { description: "1h", price: 30 }
                    ],
                    minPrice: 30,
                    maxPrice: 30
                  },
                  {
                    name: "F-Tips Extensions",
                    options: [
                      { description: "5h - 7h", price: 150, priceMax: 240 }
                    ],
                    minPrice: 150,
                    maxPrice: 240
                  },
                  {
                    name: "K-Tips Extensions",
                    options: [
                      { description: "7h - 8h", price: 375, priceMax: 475 }
                    ],
                    minPrice: 375,
                    maxPrice: 475
                  }
                ]
              },
              {
                service: "Haircuts & Hairdressing",
                subService: [
                  {
                    name: "Blow Dry",
                    options: [
                      { description: "Short Hair", duration: "30m", price: 20 },
                      { description: "Medium Length Hair", duration: "45m", price: 30 },
                      { description: "Long Hair", duration: "45m", price: 40 }
                    ],
                    minPrice: 20,
                    maxPrice: 40
                  },
                  {
                    name: "Wash & Blow Dry",
                    options: [
                      { description: "Short Hair", duration: "30m", price: 25 },
                      { description: "Medium Length Hair", duration: "45m", price: 35 },
                      { description: "Long Hair", duration: "45m", price: 45 }
                    ],
                    minPrice: 25,
                    maxPrice: 45
                  },
                  {
                    name: "Luxury Wash & Blow Dry",
                    options: [
                      { description: "Short Hair", duration: "1h30m", price: 55 },
                      { description: "Medium Length Hair", duration: "2h", price: 65 },
                      { description: "Long Hair", duration: "2h30m", price: 75 }
                    ],
                    minPrice: 55,
                    maxPrice: 75
                  },
                  {
                    name: "Silk Press Treatment",
                    options: [
                      { description: "Short Hair", duration: "2h30m", price: 75 },
                      { description: "Medium Length Hair", duration: "2h30m", price: 85 },
                      { description: "Long Hair", duration: "3h", price: 95 }
                    ],
                    minPrice: 75,
                    maxPrice: 95
                  }
                ]
              },
              {
                service: "Hair Treatments",
                subService: [
                  {
                    name: "Hair Botox Treatment",
                    options: [
                      { description: "Short Hair", duration: "3h30m", price: 90 },
                      { description: "Medium Length Hair", duration: "3h30m", price: 120 },
                      { description: "Long Hair", duration: "3h30m", price: 150 }
                    ],
                    minPrice: 90,
                    maxPrice: 150
                  },
                  {
                    name: "Hair Conditioning Treatment with Blow Dry",
                    options: [
                      { description: "Short Hair", duration: "1h", price: 45 },
                      { description: "Medium Length Hair", duration: "1h", price: 55 },
                      { description: "Long Hair", duration: "1h", price: 65 }
                    ],
                    minPrice: 45,
                    maxPrice: 65
                  },
                  {
                    name: "Olaplex Treatment with Blow Dry",
                    options: [
                      { description: "Short Hair", duration: "1h", price: 45 },
                      { description: "Medium Length Hair", duration: "1h", price: 55 },
                      { description: "Long Hair", duration: "1h", price: 65 }
                    ],
                    minPrice: 45,
                    maxPrice: 65
                  },
                  {
                    name: "Olaplex Treatment with Haircut & Blow Dry",
                    options: [
                      { description: "Short Hair", duration: "1h30m", price: 60 },
                      { description: "Medium Length Hair", duration: "1h30m", price: 70 },
                      { description: "Long Hair", duration: "1h30m", price: 80 }
                    ],
                    minPrice: 60,
                    maxPrice: 80
                  },
                  {
                    name: "Avlon Texture Release Treatment",
                    options: [
                      { description: "Short Hair", duration: "3h30m", price: 130 },
                      { description: "Medium Length Hair", duration: "4h", price: 150 },
                      { description: "Long Hair", duration: "5h", price: 180 }
                    ],
                    minPrice: 130,
                    maxPrice: 180
                  },
                  {
                    name: "Keratin & Permanent Straightening Treatment",
                    options: [
                      { description: "Short Hair", duration: "3h30m", price: 90 },
                      { description: "Medium Length Hair", duration: "3h30m", price: 120 },
                      { description: "Long Hair", duration: "3h30m", price: 150 }
                    ],
                    minPrice: 90,
                    maxPrice: 150
                  }
                ]
              }
            ],
            ernesta: [
                  {
                    service: "Removals & Repairs",
                    subService: [
                      {
                        name: "Nail Repair",
                        options: [
                          { description: "Standard", duration: "30m", price: 6 }
                        ],
                        minPrice: 6,
                        maxPrice: 6
                      },
                      {
                        name: "Gel Removal",
                        options: [
                          { description: "Standard", duration: "30m", price: 10 }
                        ],
                        minPrice: 10,
                        maxPrice: 10
                      },
                      {
                        name: "Gel Removal & Nail Tidy",
                        options: [
                          { description: "Standard", duration: "1h5m", price: 35 }
                        ],
                        minPrice: 35,
                        maxPrice: 35
                      }
                    ]
                  },
                  {
                    service: "Manicure & Pedicures",
                    subService: [
                      {
                        name: "Premium Hard Gel Overlay",
                        options: [
                          { description: "Standard", duration: "2h30m", price: 65 }
                        ],
                        minPrice: 65,
                        maxPrice: 65
                      },
                      {
                        name: "Premium Hard Gel Overlay Infill",
                        options: [
                          { description: "Standard", duration: "2h45m", price: 65 }
                        ],
                        minPrice: 65,
                        maxPrice: 65
                      },
                      {
                        name: "Bio Sculpture Plain Gel Manicure",
                        options: [
                          { description: "Standard", duration: "2h25m", price: 65 }
                        ],
                        minPrice: 65,
                        maxPrice: 65
                      },
                      {
                        name: "Bio Sculpture Gel Pedicure",
                        options: [
                          { description: "Standard", duration: "2h25m", price: 65 }
                        ],
                        minPrice: 65,
                        maxPrice: 65
                      },
                      {
                        name: "Elim Medical Grade Pedicure with Bio Sculpture Gel",
                        options: [
                          { description: "Standard", duration: "2h30m", price: 85 }
                        ],
                        minPrice: 85,
                        maxPrice: 85
                      }
                    ]
                  }
                ],
          
                marie: [
                      {
                        service: "Removals & Repairs",
                        subService: [
                          {
                            name: "Nail Repair",
                            options: [
                              { description: "Standard", duration: "30m", price: 6 }
                            ],
                            minPrice: 6,
                            maxPrice: 6
                          },
                          {
                            name: "Gel Removal",
                            options: [
                              { description: "Standard", duration: "30m", price: 10 }
                            ],
                            minPrice: 10,
                            maxPrice: 10
                          },
                          {
                            name: "Acrylic Removal",
                            options: [
                              { description: "Standard", duration: "1h", price: 20 }
                            ],
                            minPrice: 20,
                            maxPrice: 20
                          },
                          {
                            name: "Gel Removal & Nail Tidy",
                            options: [
                              { description: "Standard", duration: "1h5m", price: 35 }
                            ],
                            minPrice: 35,
                            maxPrice: 35
                          }
                        ]
                      },
                      {
                        service: "Nail Extensions",
                        subService: [
                          {
                            name: "Acrylic Full Set",
                            options: [
                              { description: "Standard", duration: "2h45m", price: 45 }
                            ],
                            minPrice: 45,
                            maxPrice: 45
                          },
                          {
                            name: "Acrylic Infill",
                            options: [
                              { description: "Standard", duration: "2h45m", price: 40 }
                            ],
                            minPrice: 40,
                            maxPrice: 40
                          },
                          {
                            name: "Gel Full Set",
                            options: [
                              { description: "Standard", duration: "2h30m", price: 55 }
                            ],
                            minPrice: 55,
                            maxPrice: 55
                          },
                          {
                            name: "Gel Infill",
                            options: [
                              { description: "Standard", duration: "2h30m", price: 50 }
                            ],
                            minPrice: 50,
                            maxPrice: 50
                          }
                        ]
                      },
                      {
                        service: "Manicure & Pedicures",
                        subService: [
                          {
                            name: "Hard Gel Overlay",
                            options: [
                              { description: "Standard", duration: "2h30m", price: 50 }
                            ],
                            minPrice: 50,
                            maxPrice: 50
                          },
                          {
                            name: "Gel Polish (Shellac)",
                            options: [
                              { description: "Standard", duration: "1h", price: 25 }
                            ],
                            minPrice: 25,
                            maxPrice: 25
                          },
                          {
                            name: "Gel Polish (Shellac) with French",
                            options: [
                              { description: "Standard", duration: "1h20m", price: 30 }
                            ],
                            minPrice: 30,
                            maxPrice: 30
                          },
                          {
                            name: "Classic Manicure",
                            options: [
                              { description: "Standard", duration: "1h", price: 20 }
                            ],
                            minPrice: 20,
                            maxPrice: 20
                          },
                          {
                            name: "Spa Manicure",
                            options: [
                              { description: "Standard", duration: "1h30m", price: 30 }
                            ],
                            minPrice: 30,
                            maxPrice: 30
                          },
                          {
                            name: "Dry Pedicure",
                            options: [
                              { description: "Standard", duration: "1h", price: 30 }
                            ],
                            minPrice: 30,
                            maxPrice: 30
                          },
                          {
                            name: "Deluxe Spa Pedicure",
                            options: [
                              { description: "Standard", duration: "2h15m", price: 40 }
                            ],
                            minPrice: 40,
                            maxPrice: 40
                          },
                          {
                            name: "Deluxe Spa Pedicure with gel polish",
                            options: [
                              { description: "Standard", duration: "2h30m", price: 45 }
                            ],
                            minPrice: 45,
                            maxPrice: 45
                          }
                        ]
                      }
                    ],
                
  };
  