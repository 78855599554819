import React, { useState } from 'react';
import './Services.css';
import arrow from '../Assets/images/arrow.png';
import { services } from '../Constants';

// Helper functions for calculating minimum and maximum prices
const getMinPrice = (sub) => {
  if (sub.options && sub.options.length > 0) {
    return Math.min(...sub.options.map(option => option.price));
  }
  return sub.price || 0;
};

const getMaxPrice = (sub) => {
  if (sub.options && sub.options.length > 0) {
    return Math.max(...sub.options.map(option => option.priceMax || option.price));
  }
  return null;
};

export default function ServiceDropDown() {
  const [openStylist, setOpenStylist] = useState(null);

  const toggleAccordion = (stylist) => {
    setOpenStylist(openStylist === stylist ? null : stylist);
  };

  return (
    <div className="accordion">
      {Object.keys(services).map((category) => (
        <div key={category}>
          {/* Accordion Header */}
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(category)}
            style={{ padding: '30px 0' }}
          >
            {category === 'braids_locs' ? 'braids & locs' : category}
            <img
              src={arrow}
              className={openStylist === category ? 'arrow-open' : 'arrow-closed'}
              alt="Toggle Arrow"
            />
          </div>

          {/* Accordion Body */}
          <div
            className={`accordion-body ${openStylist === category ? 'open' : 'collapsed'}`}
          >
            <div className="accordion-content">
              {services[category].map((serviceCategory, idx) => (
                <div key={idx} style={{ marginBottom: '15px' }}>
                  {/* Service Header */}
                  <h4 style={{ fontWeight: 'bold' }}>{serviceCategory.service}</h4>
                  {/* Sub Services */}
                  <ul style={{ paddingLeft: '20px' }}>
                    {serviceCategory.subService.map((sub, subIdx) => (
                      <li
                        key={subIdx}
                        style={{
                          listStyleType: 'none',
                          margin: '5px 0',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>{sub.name}</span>
                        <span style={{
                          textAlign: 'right',
                          whiteSpace: 'nowrap' 
                        }}>
                          £{getMinPrice(sub)}
                          {getMaxPrice(sub) ? ` - £${getMaxPrice(sub)}` : ''}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
