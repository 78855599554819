import React from 'react'
import './Team.css'
import yolanda from '../Assets/images/staff/yolanda.JPG'
import niquee from '../Assets/images/staff/niquee.JPG'
import josephine from '../Assets/images/staff/Josephine.webp'
import giselle from '../Assets/images/staff/Giselle.webp'
import kiyla from '../Assets/images/staff/Kiyla.webp'
import marie from '../Assets/images/staff/Marie.webp'
import mozhi from '../Assets/images/staff/Mozhi.webp'
import salem from '../Assets/images/staff/Salem.webp'
import sam from '../Assets/images/staff/Sam.webp'

const staff = [
  {name: "yolanda", role: "Braidert", img:yolanda },
  {name: "niquee", role: "Extensions Specialist", img: niquee },
  {name: "josephine", role: "Extensions Specialist ", img:josephine },
  {name: "giselle", role: "Braider", img:giselle },
  {name: "kiyla", role: "Extensions Specialist ", img: kiyla},
  {name: "marie", role: "Nail Technician ", img:marie },
  {name: "mozhi", role: "Senior Hairdresser ", img: mozhi},
  {name: "salem", role: "Hairstylist", img:salem },
  {name: "sam", role: "Nail Technician ", img:sam },
]
export default function Team() {
  return (
    <div id='team'>
      <div id='team-container'>
        <h2 style={{color: '#9D7A68'}}>the team</h2>
        <p style={{width: 800, maxWidth: '100%'}}>Each member of our team brings skill, creativity and a genuine dedication to understanding and enhancing your unique beauty. At art.salon, we’re honoured to be a part of your personal style journey.</p>
          
        <div style={{display:'flex', justifyContent:'space-between', margin: '50px 0', flexWrap:'wrap'}}>
          {staff.map((member, i) => {
            return (
              <div className='staff-img' key={i}>
                <img style={{width: '100%'}} src={member.img}/>
                <p style={{fontFamily: 'Kamerik, sans-serif', fontSize: 22}}>{member.name}</p>
                <p style={{marginBottom: 10}}>{member.role}</p>
              </div>
            )
          })}
         
        </div>
      </div>
      
    </div>
  )
}
