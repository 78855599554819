import React, { useState } from 'react'
import './Services.css'
import services from '../Assets/images/services.webp'
import ServiceDropDown from './ServiceDropDown'
import StylistDropdown from './StylistDropdown'

export default function Services({ setOpen }) {

  const [ tab, setTab ] = useState(1)
  return (
    <div id='services'>
      <h2 style={{opacity: 0.7}}>services</h2>
      
      <img src={services} id='service-img-mobile' className='mobile'/>

      <div style={{marginLeft: 50}} className='mobile-remove'>
        <p  style={{width: '95%'}}>Our services go beyond styling; they’re designed to give you a memorable, refreshing experience that feels tailored just for you. Our salon offers a range of premium services, from haircuts and styling to treatments and colour designed to suit your needs, hair type, and personal preferences.</p>

        <div id='service-container' >

          <div >
            <div style={{marginBottom: 20, marginTop: 10}}>
              <button 
                className={ tab === 1 ? 'service-btn-selected' : 'service-btn'} 
                onClick={() => setTab(1)}
                style={{marginRight: 20}}
              >
                  view all services
              </button>
              {/*<button 
                className={ tab === 2 ? 'service-btn-selected' : 'service-btn'} 
                onClick={() => setTab(2)}>
                  view by stylist
              </button>*/}
            </div>
            {tab === 1 && <ServiceDropDown />}
            {tab === 2 && <StylistDropdown />}

            <button style={{marginTop: 50}} className='black-btn' onClick={ () => setOpen(true)}>Book Now</button>
          </div>

          <img src={services} id='service-img' className='mobile-remove'/>

        </div>

      </div>

      <div style={{width: '100%', padding: '1rem'}} className='mobile'>
        <p >Our services go beyond styling; they’re designed to give you a memorable, refreshing experience that feels tailored just for you. Our salon offers a range of premium services, from haircuts and styling to treatments and colour designed to suit your needs, hair type, and personal preferences.</p>

        <div id='service-container' >

          <div >
            <div style={{marginBottom: 20, marginTop: 10}}>
              <button 
                className={ tab === 1 ? 'service-btn-selected' : 'service-btn'} 
                onClick={() => setTab(1)}
                style={{marginRight: 20, fontSize: 18}}
              >
                  view all services
              </button>
              {/*<button 
                className={ tab === 2 ? 'service-btn-selected' : 'service-btn'} 
                style={{fontSize: 18}}
                onClick={() => setTab(2)}>
                  view by stylist
              </button>*/}
            </div>
            {tab === 1 && <ServiceDropDown />}
            {tab === 2 && <StylistDropdown />}

            <button style={{marginTop: 50}} className='black-btn' onClick={ () => setOpen(true)}>Book Now</button>
          </div>

          <img src={services} id='service-img' className='mobile-remove'/>

        </div>

      </div>
        
    </div>
  )
}
